import React, { useState } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";

const Layout = ({ handleLogout }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const logout = () => {
    handleLogout();
    navigate("/login");
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      {/* Affichage de la croix de fermeture en overlay sur mobile */}
      {sidebarOpen && (
        <div className="fixed inset-0 z-40 md:hidden flex justify-end items-start p-4">
          <button
            className="focus:outline-none"
            onClick={() => setSidebarOpen(false)}
            aria-label="Fermer le menu"
          >
            <svg className="h-8 w-8 text-white" viewBox="0 0 24 24">
              <path
                d="M6 18L18 6M6 6l12 12"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
      )}

      <div
        className={`fixed inset-y-0 left-0 z-50 transform bg-gray-900 text-white p-6 transition-transform duration-300 
          ${sidebarOpen ? "translate-x-0" : "-translate-x-full"} 
          md:relative md:translate-x-0 md:w-1/5`}
      >
        <div className="flex items-center justify-between md:justify-start mb-6">
          <h2 className="text-4xl font-bold">
            <span>OPUS</span> <span className="text-yellow-400">OYSTER</span>
          </h2>
        </div>
        <ul className="space-y-4">
          <li>
            <Link
              to="/lots"
              className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
              onClick={() => setSidebarOpen(false)}
            >
              Lots d'huîtres
            </Link>
          </li>
          <li>
            <Link
              to="/inventaire"
              className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
              onClick={() => setSidebarOpen(false)}
            >
              Inventaire
            </Link>
          </li>
          <li>
            <Link
              to="/vivier"
              className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
              onClick={() => setSidebarOpen(false)}
            >
              Vivier
            </Link>
          </li>
          <li>
            <Link
              to="/etat-huitres"
              className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
              onClick={() => setSidebarOpen(false)}
            >
              Etat des huîtres
            </Link>
          </li>
          <li>
            <Link
              to="/mouvements/ajouter"
              className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
              onClick={() => setSidebarOpen(false)}
            >
              Déplacer les huîtres
            </Link>
          </li>
          <li>
            <Link
              to="/mouvements/voir"
              className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
              onClick={() => setSidebarOpen(false)}
            >
              Voir les déplacements d'huîtres
            </Link>
          </li>
        </ul>
        <button
          onClick={logout}
          className="mt-6 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition-colors"
        >
          Déconnexion
        </button>
      </div>
      {/* Header mobile */}
      <div className="md:hidden bg-gray-900 text-white p-4 flex items-center justify-between">
        <h2 className="text-xl font-bold">
          <span>OPUS</span> <span className="text-yellow-400">OYSTER</span>
        </h2>
        {/* Afficher le bouton burger uniquement si la sidebar est fermée */}
        {!sidebarOpen && (
          <button
            onClick={() => setSidebarOpen(true)}
            className="focus:outline-none"
            aria-label="Ouvrir le menu"
          >
            <svg className="h-6 w-6 text-white" viewBox="0 0 24 24">
              <path
                d="M4 6h16M4 12h16M4 18h16"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        )}
      </div>
      {/* Contenu principal */}
      <div className="flex-1 p-6 bg-gray-100">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
