import axios from "axios";

//https://opus-oyster.opus-international.fr/api
// ou http://localhost:3001/api

const api = axios.create({
  baseURL: "https://opus-oyster.opus-international.fr/api",
});

export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common["Authorization"];
  }
};

export const login = (email, password) => {
  return api.post("/login", { email, password });
};

// Inventaire API
export const fetchInventaire = () => {
  return api.get("/inventaire");
};

export const updateInventaire = async (numeroRang, updatedTypeLotIds) => {
  return api.put(`/inventaire/${numeroRang}`, {
    type_lot_ids: updatedTypeLotIds,
  });
};

export const addInventaire = (data) => {
  // data: { numero_rang, poids_total }
  return api.post("/inventaire", data);
};

export const fetchLieux = () => {
  return api.get("/lieux");
};

// Récupérer l'inventaire d'un rang (détails simples)
export const fetchTypesByRang = (numeroRang) => {
  return api.get(`/inventaire/rang/${numeroRang}`);
};

// Récupérer les types d'huîtres associés à un rang avec les quantités (ex. en Parc)
export const fetchTypesInRang = (numeroRang) => {
  return api.get(`/inventaire/${numeroRang}/types`);
};

export const fetchTypeInventaire = (type_lot_id) => {
  return api.get(`/types_lots/${type_lot_id}/inventaire`);
};

export const fetchAvailableRanks = (typeLotId) => {
  return api.get(`/types_lots/${typeLotId}/available_ranks`);
};

export const fetchLots = (includeArchived = false) => {
  return api.get(`/lots?includeArchived=${includeArchived}`);
};

export const addLot = (lot) => {
  // Lot: { nom, date_reception, provenance, type_huitres: [{ type, poids_initial, numero_rang_actuel (optionnel) }] }
  return api.post("/lots", lot);
};

export const updateLot = (id, lot) => {
  // Lot: { nom, date_reception, provenance }
  return api.put(`/lots/${id}`, lot);
};

export const deleteLot = (id) => {
  return api.delete(`/lots/${id}`);
};

export const fetchTypesByLot = (lotId) => {
  return api.get(`/lots/${lotId}/types`);
};

export const fetchFullLots = () => {
  return api.get("/lots/full");
};

// Mouvements API
export const fetchMouvements = () => {
  return api.get("/mouvements");
};

export const archiveLot = (id) => {
  return api.put(`/lots/${id}/archive`);
};

export const unarchiveLot = (id) => {
  return api.put(`/lots/${id}/unarchive`);
};

export const addMouvement = (mouvement) => {
  // Mouvement: { type_lot_id, date_mouvement, lieu_from, lieu_to, lot_id, quantite, numero_rang_from, numero_rang_to, acheteur }
  return api.post("/mouvements", mouvement);
};

export const fetchMouvementsByLot = (lot_id) => {
  return api.get(`/mouvements/${lot_id}`);
};

// Vivier API
export const fetchVivier = () => {
  return api.get("/vivier");
};

export const addVivierEntry = (entry) => {
  // entry: { date, description, comments }
  return api.post("/vivier", entry);
};

export const updateVivierEntry = (id, entry) => {
  return api.put(`/vivier/${id}`, entry);
};

export const deleteVivierEntry = (id) => {
  return api.delete(`/vivier/${id}`);
};

// État des huîtres en "Marais" (agrégé sur toutes les variantes de Marais)
export const fetchEtatHuitresMarais = (includeArchived = false) => {
  return api.get(`/etat-huitres/marais?includeArchived=${includeArchived}`);
};

export const fetchEtatHuitresDetail = (includeArchived = false) => {
  return api.get(`/etat-huitres/detail?includeArchived=${includeArchived}`);
};

// État des huîtres par type en "Marais"
export const fetchEtatHuitresMaraisParType = (includeArchived = false) => {
  return api.get(
    `/etat-huitres/marais/types?includeArchived=${includeArchived}`
  );
};

// Récupérer la quantité restante pour un type (agrégation depuis quantites_lieux)
export const fetchRemainingQuantity = (typeId) => {
  return api.get(`/types_lots/${typeId}/remaining_quantity`);
};

// Récupérer les types d'huîtres d'un lot avec la quantité totale (toutes localisations confondues)
export const fetchTypesByLotId = (lot_id) => {
  return api.get(`/types_lots/by-lot/${lot_id}`);
};

// Récupérer les lots pour un type d'huîtres
export const fetchLotsByTypeHuitre = (type_huitre) => {
  return api.get(`/types_lots/by-type/${type_huitre}`);
};

export const fetchAcheteurs = () => {
  return api.get("/acheteurs");
};

export default api;
