import React, { useState, useEffect } from "react";
import { fetchLots, fetchMouvementsByLot } from "./api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MovementsList = () => {
  const [lots, setLots] = useState([]);
  const [selectedLot, setSelectedLot] = useState("");
  const [movements, setMovements] = useState([]);
  const [includeArchived, setIncludeArchived] = useState(false);

  // Chargement des lots
  const loadLots = async (archived = false) => {
    try {
      const response = await fetchLots(archived);
      setLots(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Failed to fetch lots:", error);
      toast.error("Échec du chargement des lots");
    }
  };

  useEffect(() => {
    loadLots();
  }, []);

  // Lorsqu'un lot est sélectionné, charger ses mouvements
  const handleLotChange = async (e) => {
    const lotId = e.target.value;
    setSelectedLot(lotId);
    if (lotId) {
      try {
        const response = await fetchMouvementsByLot(lotId);
        setMovements(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error("Failed to fetch movements:", error);
        toast.error("Échec du chargement des déplacements");
      }
    } else {
      setMovements([]);
    }
  };

  // Gestion de l'inclusion des lots archivés
  const handleIncludeArchivedChange = () => {
    setIncludeArchived(!includeArchived);
    loadLots(!includeArchived);
    setSelectedLot("");
    setMovements([]);
  };

  // Récupérer le nom du lot sélectionné à partir de la liste
  const selectedLotName = lots.find((lot) => lot.id === selectedLot)?.nom || "";

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">Historique des déplacements des lots</h2>
      <div className="mb-4">
        <label className="block text-gray-700 mb-1">Sélectionner un lot</label>
        <select
          value={selectedLot}
          onChange={handleLotChange}
          className="w-full px-3 py-2 border rounded"
        >
          <option value="">Sélectionner un lot</option>
          {lots.map((lot) => (
            <option key={lot.id} value={lot.id}>
              {lot.nom}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4 flex items-center">
        <input
          type="checkbox"
          id="includeArchived"
          checked={includeArchived}
          onChange={handleIncludeArchivedChange}
        />
        <label htmlFor="includeArchived" className="ml-2 text-gray-700">
          Inclure les lots archivés
        </label>
      </div>
      {selectedLot && (
        <p className="mb-4 text-blue-500 font-semibold">
          Lot sélectionné : {selectedLotName}
        </p>
      )}
      {movements.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white table-auto">
            <thead>
              <tr className="bg-gray-200">
                <th className="py-2 border px-2">Date de déplacement</th>
                <th className="py-2 border px-2">Lieu de départ</th>
                <th className="py-2 border px-2">Lieu d'arrivée</th>
                <th className="py-2 border px-2">Type / Quantité</th>
                <th className="py-2 border px-2">Numéro de rang</th>
                <th className="py-2 border px-2">Acheteur</th>
              </tr>
            </thead>
            <tbody>
              {movements.map((movement) => (
                <tr key={movement.id} className="hover:bg-gray-100">
                  <td className="border px-4 py-2 text-sm">
                    {new Date(movement.date_mouvement).toLocaleDateString(
                      "fr-FR"
                    )}
                  </td>
                  <td className="border px-4 py-2 text-sm">
                    {movement.lieu_from}
                  </td>
                  <td className="border px-4 py-2 text-sm">
                    {movement.lieu_to}
                  </td>
                  <td className="border px-4 py-2 text-sm">
                    {movement.quantite} kg
                  </td>
                  <td className="border px-4 py-2 text-sm">
                    {movement.lieu_to === "Parc"
                      ? movement.numero_rang_to || ""
                      : ""}
                  </td>
                  <td className="border px-4 py-2 text-sm">
                    {movement.lieu_to === "Vente"
                      ? movement.vente_acheteur_to || ""
                      : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-gray-500">Aucun déplacement trouvé pour ce lot.</p>
      )}
    </div>
  );
};

export default MovementsList;
