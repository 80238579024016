import React, { useEffect, useState } from "react";
import { fetchEtatHuitresDetail } from "./api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EtatHuitres = () => {
  const [etatHuitres, setEtatHuitres] = useState([]);
  const [viewType, setViewType] = useState("lot"); // "lot" ou "type"
  const [includeArchived, setIncludeArchived] = useState(false);
  const [selectedLot, setSelectedLot] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  useEffect(() => {
    const loadEtatHuitres = async () => {
      try {
        // L'endpoint renvoie pour chaque objet :
        // { lot_nom, type_huitre, quantite_vivier, quantite_marais_1, quantite_marais_2, quantite_marais_3, quantite_parc, quantite_vente }
        const response = await fetchEtatHuitresDetail(includeArchived);
        setEtatHuitres(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error("Failed to fetch état huitres:", error);
        toast.error("Échec du chargement de l'état des huîtres");
      }
    };
    loadEtatHuitres();
  }, [includeArchived]);

  const handleLotClick = (lotNom) => {
    const lotDetails = etatHuitres.filter((item) => item.lot_nom === lotNom);
    setSelectedLot({ nom: lotNom, types: lotDetails });
    setSelectedType(null);
  };

  const handleTypeClick = (typeHuitre) => {
    const typeDetails = etatHuitres.filter(
      (item) => item.type_huitre === typeHuitre
    );
    setSelectedType({ type: typeHuitre, lots: typeDetails });
    setSelectedLot(null);
  };

  // Groupement par lot
  const groupedByLot = etatHuitres.reduce((acc, item) => {
    if (!acc[item.lot_nom]) {
      acc[item.lot_nom] = [];
    }
    acc[item.lot_nom].push(item);
    return acc;
  }, {});

  // Groupement par type
  const groupedByType = etatHuitres.reduce((acc, item) => {
    if (!acc[item.type_huitre]) {
      acc[item.type_huitre] = [];
    }
    acc[item.type_huitre].push(item);
    return acc;
  }, {});

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">État des huîtres</h2>
      <div className="flex mb-4 space-x-4">
        <button
          onClick={() => {
            setViewType("lot");
            setSelectedLot(null);
            setSelectedType(null);
          }}
          className={`px-4 py-2 rounded transition-colors ${
            viewType === "lot"
              ? "bg-blue-500 text-white"
              : "bg-gray-300 text-gray-800"
          } hover:bg-blue-400`}
        >
          Afficher par lot
        </button>
        <button
          onClick={() => {
            setViewType("type");
            setSelectedLot(null);
            setSelectedType(null);
          }}
          className={`px-4 py-2 rounded transition-colors ${
            viewType === "type"
              ? "bg-blue-500 text-white"
              : "bg-gray-300 text-gray-800"
          } hover:bg-blue-400`}
        >
          Afficher par type
        </button>
      </div>
      <div className="flex items-center mb-4 space-x-2">
        <input
          type="checkbox"
          checked={includeArchived}
          onChange={() => {
            setIncludeArchived(!includeArchived);
            setSelectedLot(null);
            setSelectedType(null);
          }}
        />
        <label className="ml-2">Inclure les lots archivés</label>
      </div>
      {viewType === "lot" ? (
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {Object.keys(groupedByLot).map((lotNom, index) => (
              <div
                key={index}
                onClick={() => handleLotClick(lotNom)}
                className={`p-4 border rounded shadow-lg cursor-pointer transition-colors hover:bg-blue-100 ${
                  selectedLot?.nom === lotNom
                    ? "bg-blue-200 border-blue-500"
                    : "bg-white"
                }`}
              >
                <h3 className="text-lg font-bold mb-2">{lotNom}</h3>
              </div>
            ))}
          </div>
          {selectedLot && (
            <div className="mt-6">
              <h3 className="text-xl mb-4">
                Détails du lot <strong>{selectedLot.nom}</strong>
              </h3>
              <div className="overflow-x-auto">
                <table className="w-full border-collapse border border-gray-300">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="border border-gray-300 px-4 py-2">Type</th>
                      <th className="border border-gray-300 px-4 py-2">
                        Vivier (kg)
                      </th>
                      <th className="border border-gray-300 px-4 py-2">
                        Marais 1 (kg)
                      </th>
                      <th className="border border-gray-300 px-4 py-2">
                        Marais 2 (kg)
                      </th>
                      <th className="border border-gray-300 px-4 py-2">
                        Marais 3 (kg)
                      </th>
                      <th className="border border-gray-300 px-4 py-2">
                        Parc (kg)
                      </th>
                      <th className="border border-gray-300 px-4 py-2">
                        Vente (kg)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedLot.types.map((type, index) => (
                      <tr
                        key={index}
                        className="transition-colors hover:bg-gray-100"
                      >
                        <td className="border border-gray-300 px-4 py-2 text-center">
                          Numéro {type.type_huitre}
                        </td>
                        <td className="border border-gray-300 px-4 py-2 text-center">
                          {type.quantite_vivier} kg
                        </td>
                        <td className="border border-gray-300 px-4 py-2 text-center">
                          {type.quantite_marais_1} kg
                        </td>
                        <td className="border border-gray-300 px-4 py-2 text-center">
                          {type.quantite_marais_2} kg
                        </td>
                        <td className="border border-gray-300 px-4 py-2 text-center">
                          {type.quantite_marais_3} kg
                        </td>
                        <td className="border border-gray-300 px-4 py-2 text-center">
                          {type.quantite_parc} kg
                        </td>
                        <td className="border border-gray-300 px-4 py-2 text-center">
                          {type.quantite_vente} kg
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {Object.keys(groupedByType).map((typeHuitre, index) => (
              <div
                key={index}
                onClick={() => handleTypeClick(typeHuitre)}
                className={`p-4 border rounded shadow-lg cursor-pointer transition-colors hover:bg-blue-100 ${
                  selectedType?.type === typeHuitre
                    ? "bg-blue-200 border-blue-500"
                    : "bg-white"
                }`}
              >
                <h3 className="text-lg font-bold mb-2">Numéro {typeHuitre}</h3>
              </div>
            ))}
          </div>
          {selectedType && (
            <div className="mt-6">
              <h3 className="text-xl mb-4">
                Détails pour les huîtres numéro{" "}
                <strong>{selectedType.type}</strong>
              </h3>
              <div className="overflow-x-auto">
                <table className="w-full border-collapse border border-gray-300">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="border border-gray-300 px-4 py-2">
                        Nom du lot
                      </th>
                      <th className="border border-gray-300 px-4 py-2">
                        Vivier (kg)
                      </th>
                      <th className="border border-gray-300 px-4 py-2">
                        Marais 1 (kg)
                      </th>
                      <th className="border border-gray-300 px-4 py-2">
                        Marais 2 (kg)
                      </th>
                      <th className="border border-gray-300 px-4 py-2">
                        Marais 3 (kg)
                      </th>
                      <th className="border border-gray-300 px-4 py-2">
                        Parc (kg)
                      </th>
                      <th className="border border-gray-300 px-4 py-2">
                        Vente (kg)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedType.lots.map((lot, index) => (
                      <tr
                        key={index}
                        className="transition-colors hover:bg-gray-100"
                      >
                        <td className="border border-gray-300 px-4 py-2 text-center">
                          {lot.lot_nom}
                        </td>
                        <td className="border border-gray-300 px-4 py-2 text-center">
                          {lot.quantite_vivier} kg
                        </td>
                        <td className="border border-gray-300 px-4 py-2 text-center">
                          {lot.quantite_marais_1} kg
                        </td>
                        <td className="border border-gray-300 px-4 py-2 text-center">
                          {lot.quantite_marais_2} kg
                        </td>
                        <td className="border border-gray-300 px-4 py-2 text-center">
                          {lot.quantite_marais_3} kg
                        </td>
                        <td className="border border-gray-300 px-4 py-2 text-center">
                          {lot.quantite_parc} kg
                        </td>
                        <td className="border border-gray-300 px-4 py-2 text-center">
                          {lot.quantite_vente} kg
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EtatHuitres;
