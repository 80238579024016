import React, { useEffect, useState, useRef } from "react";
import { fetchInventaire } from "./api";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "react-toastify/dist/ReactToastify.css";
import "./Inventaire.css";

Modal.setAppElement("#root");

const Inventaire = () => {
  const [groupedInventaire, setGroupedInventaire] = useState({});
  const [selectedRang, setSelectedRang] = useState(null);
  const [typesModalIsOpen, setTypesModalIsOpen] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        // On récupère tous les rangs via l'endpoint modifié
        const response = await fetchInventaire();
        // Groupement par numero_rang
        const groupedData = response.data.reduce((acc, current) => {
          const rang = current.numero_rang;
          if (!acc[rang]) {
            acc[rang] = [];
          }
          acc[rang].push(current);
          return acc;
        }, {});
        setGroupedInventaire(groupedData);
      } catch (error) {
        console.error("Failed to fetch inventaire:", error);
        toast.error("Échec du chargement de l'inventaire");
      }
    };

    loadData();
  }, []);

  const openTypesModal = (numero_rang) => {
    setSelectedRang(groupedInventaire[numero_rang]);
    setTypesModalIsOpen(true);
  };

  const closeTypesModal = () => {
    setTypesModalIsOpen(false);
    setSelectedRang(null);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl">Inventaire du parc</h2>
      </div>
      <TransitionGroup className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {Array.from({ length: 24 }, (_, i) => i + 1).map((rang) => {
          const rangData = groupedInventaire[rang] || [];
          return (
            <CSSTransition key={rang} timeout={300} classNames="fade">
              <div
                className="p-4 border rounded cursor-pointer hover:bg-gray-100 flex flex-col items-center justify-center"
                onClick={() => openTypesModal(rang)}
              >
                <h3 className="text-lg font-bold">Rang {rang}</h3>
                {rangData.length > 0 && rangData.some((r) => r.lot_nom) ? (
                  <div className="text-green-500">
                    Des huîtres sont présentes
                  </div>
                ) : (
                  <div className="text-red-500">Aucune huître présente</div>
                )}
              </div>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
      <Modal
        isOpen={typesModalIsOpen}
        onRequestClose={closeTypesModal}
        contentLabel="Détails du Rang"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <CSSTransition
          in={typesModalIsOpen}
          timeout={300}
          classNames="fade"
          unmountOnExit
          nodeRef={modalRef}
        >
          <div
            className="p-6 bg-white rounded-lg shadow-lg max-w-md mx-auto"
            ref={modalRef}
          >
            <h2 className="text-2xl mb-4">
              Détails du Rang {selectedRang && selectedRang[0].numero_rang}
            </h2>
            {selectedRang && selectedRang.length > 0 ? (
              <div className="grid grid-cols-1 gap-4">
                {selectedRang.map((detail, idx) => (
                  <div key={idx} className="p-4 border rounded shadow">
                    <p>
                      <span className="font-bold">Nom du Lot :</span>{" "}
                      {detail.lot_nom || "N/A"}
                    </p>
                    <p>
                      <span className="font-bold">Provenance :</span>{" "}
                      {detail.lot_provenance || "N/A"}
                    </p>
                    <p>
                      <span className="font-bold">Numéro :</span>{" "}
                      {detail.type_huitre || "N/A"}
                    </p>
                    <p>
                      <span className="font-bold">Quantité :</span>{" "}
                      {detail.quantite} kg
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-red-500">
                Aucune huître présente dans ce rang
              </div>
            )}
            <button
              type="button"
              onClick={closeTypesModal}
              className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 mt-4"
            >
              Fermer
            </button>
          </div>
        </CSSTransition>
      </Modal>
    </div>
  );
};

export default Inventaire;
