import React, { useEffect, useState } from "react";
import { format, parseISO, addDays } from "date-fns";
import { fr } from "date-fns/locale";
import {
  fetchFullLots,
  addLot,
  deleteLot,
  archiveLot,
  unarchiveLot,
  fetchLieux,
} from "./api";
import Modal from "react-modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Lots.css";

Modal.setAppElement("#root");

// Mise à jour de la fonction de création des types pour inclure des duos de parc
const createDefaultTypes = (locations) => {
  const nonParc = locations.filter((loc) => loc.nom !== "Parc");
  const types = [];
  for (let i = 1; i <= 5; i++) {
    const quantites = {};
    nonParc.forEach((loc) => {
      quantites[loc.nom] = 0;
    });
    types.push({
      type: i.toString(),
      quantites,
      // Remplacement des champs simples par un tableau de duo(s)
      parc_duos: [
        {
          nbPoches: 0,
          numero_rang: "",
        },
      ],
    });
  }
  return types;
};

// Fonction helper pour calculer le poids en kg d'un nombre de poches selon le type d'huîtres
const getConversionKg = (type, nbPoches) => {
  const n = parseFloat(nbPoches) || 0;
  let factor = 0;
  if (["1", "2", "3"].includes(type)) {
    factor = 10;
  } else if (type === "4") {
    factor = 8;
  } else if (type === "5") {
    factor = 6.5;
  }
  return n * factor;
};

const Lots = () => {
  const [lots, setLots] = useState([]);
  const [selectedLot, setSelectedLot] = useState(null);
  const [includeArchived, setIncludeArchived] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const defaultDate = format(addDays(new Date(), 0), "yyyy-MM-dd");

  const [locations, setLocations] = useState([]);

  const [formData, setFormData] = useState({
    nom: "",
    provenance: "",
    date_reception: defaultDate,
    type_huitres: [],
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const loadLieux = async () => {
      try {
        const response = await fetchLieux();
        setLocations(response.data);
      } catch (error) {
        console.error("Failed to fetch lieux:", error);
        toast.error("Échec du chargement des lieux");
      }
    };
    loadLieux();
  }, []);

  useEffect(() => {
    if (locations.length > 0) {
      setFormData((prev) => ({
        ...prev,
        type_huitres: createDefaultTypes(locations),
      }));
    }
  }, [locations]);

  useEffect(() => {
    const loadLots = async () => {
      try {
        const response = await fetchFullLots({ includeArchived });
        const lotsData = Array.isArray(response.data) ? response.data : [];
        lotsData.forEach((lot) => {
          if (lot.types) {
            lot.types.sort((a, b) => parseInt(a.type) - parseInt(b.type));
          }
        });
        setLots(lotsData);
      } catch (error) {
        console.error("Failed to fetch full lots:", error);
        toast.error("Échec du chargement des lots");
      }
    };
    loadLots();
  }, [includeArchived]);

  const convertQuantites = (quantitesArray) => {
    const obj = {};
    if (Array.isArray(quantitesArray)) {
      quantitesArray.forEach((item) => {
        obj[item.lieu] = item.quantite;
        if (item.lieu === "Parc") {
          obj["numero_rang"] = item.numero_rang;
        }
      });
    }
    return obj;
  };

  const openModal = (lot = null) => {
    setSelectedLot(lot);
    if (lot) {
      // Lors de l'édition, on ne récupère qu'une valeur agrégée pour le Parc,
      // on initialise alors le tableau de duo avec cette valeur.
      const convertedTypes = (lot.types || []).map((t) => {
        // Déduire le facteur de conversion en fonction du type
        let factor = 0;
        if (["1", "2", "3"].includes(t.type)) {
          factor = 10;
        } else if (t.type === "4") {
          factor = 8;
        } else if (t.type === "5") {
          factor = 6.5;
        }
        const parcData = t.quantites?.find((q) => q.lieu === "Parc") || {
          quantite: 0,
          numero_rang: "",
        };
        // On inverse le calcul pour obtenir un nb de poche (arrondi)
        const nbPoches = factor ? Math.round(parcData.quantite / factor) : 0;
        return {
          type: t.type,
          quantites: convertQuantites(t.quantites),
          // On initialise le tableau de duo avec la valeur agrégée
          parc_duos: [
            {
              nbPoches,
              numero_rang: parcData.numero_rang || "",
            },
          ],
          poids_initial: t.poids_initial,
          id: t.id,
        };
      });
      setFormData({
        nom: lot.nom,
        provenance: lot.provenance || "",
        date_reception: format(parseISO(lot.date_reception), "yyyy-MM-dd"),
        type_huitres: convertedTypes,
      });
    } else {
      setFormData({
        nom: "",
        provenance: "",
        date_reception: defaultDate,
        type_huitres: createDefaultTypes(locations),
      });
    }
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedLot(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Gestion des champs de quantités pour les autres lieux
    const quantitesRegex = /type_huitres\[(\d+)\]\.quantites\.(.+)/;
    if (quantitesRegex.test(name)) {
      const [, index, loc] = name.match(quantitesRegex);
      const newTypes = [...formData.type_huitres];
      newTypes[parseInt(index, 10)].quantites[loc] = parseFloat(value);
      setFormData({ ...formData, type_huitres: newTypes });
      return;
    }
    // Gestion classique des autres champs
    if (name.startsWith("type_huitres") && name.includes("parc")) {
      // Ancien cas pour "parc" (non utilisé avec le nouveau système)
    }
    // Gestion des champs pour les duos de Parc : nbPoches et numero_rang
    const parcDuoRegex =
      /type_huitres\[(\d+)\]\.parc_duos\[(\d+)\]\.(nbPoches|numero_rang)/;
    if (parcDuoRegex.test(name)) {
      const [, typeIndex, duoIndex, field] = name.match(parcDuoRegex);
      const newTypes = [...formData.type_huitres];
      newTypes[parseInt(typeIndex, 10)].parc_duos[parseInt(duoIndex, 10)][
        field
      ] = value;
      setFormData({ ...formData, type_huitres: newTypes });
      return;
    }
    // Pour les autres champs simples
    setFormData({ ...formData, [name]: value });
  };

  // Fonction pour ajouter un nouveau duo pour un type d'huîtres
  const addParcDuo = (typeIndex) => {
    const newTypes = [...formData.type_huitres];
    newTypes[typeIndex].parc_duos.push({ nbPoches: 0, numero_rang: "" });
    setFormData({ ...formData, type_huitres: newTypes });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Avant l'envoi, on transforme chaque type pour calculer la valeur agrégée
    const updatedTypes = formData.type_huitres.map((typeItem) => {
      let factor = 0;
      if (["1", "2", "3"].includes(typeItem.type)) {
        factor = 10;
      } else if (typeItem.type === "4") {
        factor = 8;
      } else if (typeItem.type === "5") {
        factor = 6.5;
      }
      const totalKg = typeItem.parc_duos.reduce((sum, duo) => {
        return sum + parseFloat(duo.nbPoches || 0) * factor;
      }, 0);
      // Par exemple, on peut combiner les numéros de rang en les séparant par une virgule
      const combinedRang = typeItem.parc_duos
        .map((duo) => duo.numero_rang)
        .filter((r) => r)
        .join(", ");
      return {
        ...typeItem,
        parc: totalKg,
        numero_rang: combinedRang,
      };
    });
    const payload = {
      ...formData,
      type_huitres: updatedTypes,
    };
    console.log(payload);

    try {
      await addLot(payload);
      toast.success(`Le lot ${formData.nom} a été ajouté avec succès`);
      const response = await fetchFullLots({ includeArchived });
      const lotsData = Array.isArray(response.data) ? response.data : [];
      lotsData.forEach((lot) => {
        if (lot.types) {
          lot.types.sort((a, b) => parseInt(a.type) - parseInt(b.type));
        }
      });
      setLots(lotsData);
      closeModal();
    } catch (error) {
      console.error("Failed to save lot:", error);
      toast.error("Échec de la sauvegarde du lot");
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce lot ?")) {
      try {
        await deleteLot(id);
        const response = await fetchFullLots({ includeArchived });
        const lotsData = Array.isArray(response.data) ? response.data : [];
        setLots(lotsData);
        toast.success("Le lot a été supprimé avec succès");
      } catch (error) {
        console.error("Failed to delete lot:", error);
        toast.error("Échec de la suppression du lot");
      }
    }
  };

  const handleArchive = async (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir archiver ce lot ?")) {
      try {
        await archiveLot(id);
        const response = await fetchFullLots({ includeArchived });
        const lotsData = Array.isArray(response.data) ? response.data : [];
        setLots(lotsData);
        toast.success("Le lot a été archivé avec succès");
      } catch (error) {
        console.error("Failed to archive lot:", error);
        toast.error("Échec de l'archivage du lot");
      }
    }
  };

  const handleUnarchive = async (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir désarchiver ce lot ?")) {
      try {
        await unarchiveLot(id);
        const response = await fetchFullLots({ includeArchived });
        const lotsData = Array.isArray(response.data) ? response.data : [];
        setLots(lotsData);
        toast.success("Le lot a été désarchivé avec succès");
      } catch (error) {
        console.error("Failed to unarchive lot:", error);
        toast.error("Échec du désarchivage du lot");
      }
    }
  };

  const filteredLots = lots.filter((lot) =>
    lot.nom.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const isReadOnly = selectedLot !== null;

  return (
    <div className="p-4">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold text-primary-dark mb-4 md:mb-0">
          Lots d'huîtres
        </h2>
        <div className="flex flex-wrap space-x-4 space-y-2">
          <input
            type="text"
            placeholder="Rechercher..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
          />
          <button
            className="bg-primary-green text-white py-2 px-4 rounded hover:bg-green-600 transition-colors"
            onClick={() => setIncludeArchived(!includeArchived)}
          >
            {includeArchived ? "Masquer les archivés" : "Afficher les archivés"}
          </button>
          <button
            className="bg-primary-green text-white py-2 px-4 rounded hover:bg-green-600 transition-colors"
            onClick={() => openModal(null)}
          >
            Ajouter
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredLots.map((lot) => (
          <div
            key={lot.id}
            className="p-4 border rounded shadow-lg bg-white cursor-pointer transition-colors hover:bg-blue-100"
            onClick={() => openModal(lot)}
          >
            <div className="flex flex-col justify-between">
              <h3 className="text-lg font-bold text-primary-dark mb-2">
                {lot.nom}
              </h3>
              <p className="mb-1">
                <span className="font-semibold">Date de réception:</span>{" "}
                {format(parseISO(lot.date_reception), "dd/MM/yyyy", {
                  locale: fr,
                })}
              </p>
              <p className="mb-1">
                <span className="font-semibold">Provenance:</span>{" "}
                {lot.provenance}
              </p>
              <div className="flex flex-wrap space-x-2 mt-2">
                <button
                  className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600 transition-colors"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(lot.id);
                  }}
                >
                  Supprimer
                </button>
                {lot.archived ? (
                  <button
                    className="bg-yellow-500 text-white py-1 px-3 rounded hover:bg-yellow-600 transition-colors"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUnarchive(lot.id);
                    }}
                  >
                    Désarchiver
                  </button>
                ) : (
                  <button
                    className="bg-yellow-500 text-white py-1 px-3 rounded hover:bg-yellow-600 transition-colors"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleArchive(lot.id);
                    }}
                  >
                    Archiver
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Détails du lot"
        className={`modal-content ${modalIsOpen ? "fade-in" : "fade-out"}`}
        overlayClassName={`modal-overlay ${
          modalIsOpen ? "fade-in" : "fade-out"
        }`}
        style={{
          content: {
            maxHeight: "90vh",
            overflowY: "auto",
            margin: "auto",
            width: "90vw",
            maxWidth: "900px",
            padding: "20px",
          },
        }}
      >
        <div className="relative p-6 bg-white rounded-lg shadow-lg mx-auto">
          <button
            onClick={closeModal}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none z-50"
            aria-label="Fermer la modal"
          >
            <svg className="h-6 w-6" viewBox="0 0 24 24">
              <path d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <h2 className="text-2xl mb-4 font-semibold text-primary-dark">
            {isReadOnly
              ? `Détails du lot du ${format(
                  parseISO(formData.date_reception),
                  "dd/MM/yyyy",
                  { locale: fr }
                )}`
              : "Ajouter un nouveau lot"}
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-gray-700">Nom</label>
              <input
                type="text"
                name="nom"
                value={formData.nom}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                disabled={isReadOnly}
              />
            </div>
            <div>
              <label className="block text-gray-700">Provenance</label>
              <input
                type="text"
                name="provenance"
                value={formData.provenance}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                disabled={isReadOnly}
              />
            </div>
            <div>
              <label className="block text-gray-700">Date de réception</label>
              <input
                type="date"
                name="date_reception"
                value={formData.date_reception}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                disabled={isReadOnly}
              />
            </div>
            <div>
              <h4 className="text-lg font-semibold text-primary-dark">
                Répartition par lieu pour chaque type d'huîtres
              </h4>
              <div className="overflow-x-auto">
                <table className="min-w-full table-auto">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="px-4 py-2">Type</th>
                      <th className="px-4 py-2">Marais 1 (kg)</th>
                      <th className="px-4 py-2">Marais 2 (kg)</th>
                      <th className="px-4 py-2">Marais 3 (kg)</th>
                      <th className="px-4 py-2">Vivier (kg)</th>
                      <th className="px-4 py-2">Vente (kg)</th>
                      {/* Modification de l'en-tête pour Parc */}
                      <th className="px-4 py-2">Parc (nb de poches)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.type_huitres.map((typeItem, index) => (
                      <tr
                        key={index}
                        className="text-center transition-colors hover:bg-gray-100"
                      >
                        <td className="px-4 py-2">{typeItem.type}</td>
                        <td className="px-4 py-2">
                          <input
                            type="number"
                            name={`type_huitres[${index}].quantites.Marais 1`}
                            value={typeItem.quantites["Marais 1"]}
                            onChange={handleInputChange}
                            className="w-full px-2 py-1 border rounded focus:outline-none"
                            disabled={isReadOnly}
                          />
                        </td>
                        <td className="px-4 py-2">
                          <input
                            type="number"
                            name={`type_huitres[${index}].quantites.Marais 2`}
                            value={typeItem.quantites["Marais 2"]}
                            onChange={handleInputChange}
                            className="w-full px-2 py-1 border rounded focus:outline-none"
                            disabled={isReadOnly}
                          />
                        </td>
                        <td className="px-4 py-2">
                          <input
                            type="number"
                            name={`type_huitres[${index}].quantites.Marais 3`}
                            value={typeItem.quantites["Marais 3"]}
                            onChange={handleInputChange}
                            className="w-full px-2 py-1 border rounded focus:outline-none"
                            disabled={isReadOnly}
                          />
                        </td>
                        <td className="px-4 py-2">
                          <input
                            type="number"
                            name={`type_huitres[${index}].quantites.Vivier`}
                            value={typeItem.quantites.Vivier}
                            onChange={handleInputChange}
                            className="w-full px-2 py-1 border rounded focus:outline-none"
                            disabled={isReadOnly}
                          />
                        </td>
                        <td className="px-4 py-2">
                          <input
                            type="number"
                            name={`type_huitres[${index}].quantites.Vente`}
                            value={typeItem.quantites.Vente}
                            onChange={handleInputChange}
                            className="w-full px-2 py-1 border rounded focus:outline-none"
                            disabled={isReadOnly}
                          />
                        </td>
                        <td className="px-4 py-2">
                          {typeItem.parc_duos.map((duo, duoIndex) => (
                            <div key={duoIndex} className="mb-2">
                              <input
                                type="number"
                                name={`type_huitres[${index}].parc_duos[${duoIndex}].nbPoches`}
                                value={duo.nbPoches}
                                onChange={handleInputChange}
                                className="w-full px-2 py-1 border rounded focus:outline-none"
                                disabled={isReadOnly}
                              />
                              <input
                                type="text"
                                name={`type_huitres[${index}].parc_duos[${duoIndex}].numero_rang`}
                                value={duo.numero_rang}
                                onChange={handleInputChange}
                                className="w-full px-2 py-1 border rounded focus:outline-none mt-1"
                                placeholder="Numéro de Rang"
                                disabled={isReadOnly}
                              />
                              <div className="text-sm text-gray-500">
                                (représente{" "}
                                {getConversionKg(typeItem.type, duo.nbPoches)}{" "}
                                kg)
                              </div>
                            </div>
                          ))}
                          {!isReadOnly && (
                            <button
                              type="button"
                              onClick={() => addParcDuo(index)}
                              className="text-blue-500 underline text-sm"
                            >
                              Ajouter un duo
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex flex-wrap justify-end space-x-4 mt-4">
              {isReadOnly ? (
                <button
                  type="button"
                  onClick={closeModal}
                  className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 transition-colors"
                >
                  Fermer
                </button>
              ) : (
                <>
                  <button
                    type="submit"
                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
                  >
                    Ajouter
                  </button>
                  <button
                    type="button"
                    onClick={closeModal}
                    className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 transition-colors"
                  >
                    Annuler
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default Lots;
