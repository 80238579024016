import React, { useEffect, useState } from "react";
import {
  fetchLots,
  addMouvement,
  fetchTypesByLot,
  fetchLieux,
  fetchAvailableRanks,
} from "./api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Mouvements = () => {
  const [lots, setLots] = useState([]);
  const [types, setTypes] = useState([]);
  const [locations, setLocations] = useState([]);
  const [availableRanksFrom, setAvailableRanksFrom] = useState([]);
  const [availableRanksTo, setAvailableRanksTo] = useState([]);
  const [formLocked, setFormLocked] = useState(false);

  const [formData, setFormData] = useState({
    lot_id: "",
    type_lot_id: "",
    date_mouvement: new Date().toISOString().split("T")[0],
    lieu_from: "",
    lieu_to: "",
    quantite: "",
    numero_rang_from: "",
    numero_rang_to: "",
    vente_acheteur_from: "",
    vente_acheteur_to: "",
  });

  // Charger les lots non archivés
  useEffect(() => {
    const loadLots = async () => {
      try {
        const response = await fetchLots(false);
        const lotsData = Array.isArray(response.data) ? response.data : [];
        const filteredLots = lotsData.filter((lot) => !lot.archived);
        setLots(filteredLots);
      } catch (error) {
        console.error("Failed to fetch lots:", error);
        toast.error("Échec du chargement des lots");
      }
    };
    loadLots();
  }, []);

  // Charger les lieux dynamiquement
  useEffect(() => {
    const loadLieux = async () => {
      try {
        const response = await fetchLieux();
        setLocations(response.data); // Chaque lieu: { id, nom }
      } catch (error) {
        console.error("Failed to fetch lieux:", error);
        toast.error("Échec du chargement des lieux");
      }
    };
    loadLieux();
  }, []);

  // Lors du changement du lot, réinitialiser les champs dépendants et charger les types associés
  const handleLotChange = async (e) => {
    const lotId = e.target.value;
    setFormData({
      ...formData,
      lot_id: lotId,
      type_lot_id: "",
      lieu_from: "",
      numero_rang_from: "",
      quantite: "",
    });
    setAvailableRanksFrom([]);
    setAvailableRanksTo([]);
    if (lotId) {
      try {
        const response = await fetchTypesByLot(lotId);
        setTypes(response.data);
      } catch (error) {
        console.error("Failed to fetch types:", error);
        toast.error("Échec du chargement des types d'huîtres");
      }
    } else {
      setTypes([]);
    }
  };

  // Lors du changement du type, réinitialiser certains champs et, si le lieu d'origine est "Parc", charger les rangs disponibles
  const handleTypeChange = async (e) => {
    const typeId = e.target.value;
    setFormData({
      ...formData,
      type_lot_id: typeId,
      lieu_from: "",
      numero_rang_from: "",
      quantite: "",
    });
    setAvailableRanksFrom([]);
    setAvailableRanksTo([]);
    if (typeId && formData.lieu_from === "Parc") {
      try {
        const response = await fetchAvailableRanks(typeId);
        setAvailableRanksFrom(response.data);
      } catch (error) {
        console.error("Failed to fetch available ranks:", error);
        toast.error("Échec du chargement des rangs pour ce type");
      }
    }
  };

  // Lors du changement du lieu de départ
  const handleLieuFromChange = async (e) => {
    const lieu = e.target.value;
    setFormData({
      ...formData,
      lieu_from: lieu,
      numero_rang_from: "",
      quantite: "",
    });
    if (lieu === "Parc" && formData.type_lot_id) {
      try {
        const response = await fetchAvailableRanks(formData.type_lot_id);
        setAvailableRanksFrom(response.data);
      } catch (error) {
        console.error("Failed to fetch available ranks:", error);
        toast.error("Échec du chargement des rangs pour ce type");
      }
    } else {
      setAvailableRanksFrom([]);
    }
  };

  // Lors du changement du lieu d'arrivée
  const handleLieuToChange = async (e) => {
    const lieu = e.target.value;
    setFormData({
      ...formData,
      lieu_to: lieu,
      numero_rang_to: "",
    });
    if (lieu === "Parc" && formData.type_lot_id) {
      try {
        const response = await fetchAvailableRanks(formData.type_lot_id);
        setAvailableRanksTo(response.data);
      } catch (error) {
        console.error("Failed to fetch available ranks:", error);
        toast.error("Échec du chargement des rangs pour ce type");
      }
    } else {
      setAvailableRanksTo([]);
    }
  };

  // Gestion des autres inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Calculer la quantité max disponible pour le type sélectionné dans le lieu de départ
  const getMaxAvailable = () => {
    if (!formData.type_lot_id || !formData.lieu_from) return null;
    const selectedType = types.find(
      (t) => t.id.toString() === formData.type_lot_id
    );
    if (!selectedType || !selectedType.quantites) return null;
    if (formData.lieu_from === "Parc" && formData.numero_rang_from) {
      const rank = availableRanksFrom.find(
        (r) => r.numero_rang.toString() === formData.numero_rang_from
      );
      return rank ? rank.quantite : 0;
    }
    return selectedType.quantites[formData.lieu_from] || 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const maxAvailable = getMaxAvailable();
    if (maxAvailable !== null && parseFloat(formData.quantite) > maxAvailable) {
      toast.error(
        `La quantité ne peut pas dépasser ${maxAvailable} kg pour le rang sélectionné.`
      );
      return;
    }
    try {
      await addMouvement(formData);
      toast.success("Déplacement ajouté avec succès");
      setFormData({
        lot_id: "",
        type_lot_id: "",
        date_mouvement: new Date().toISOString().split("T")[0],
        lieu_from: "",
        lieu_to: "",
        quantite: "",
        numero_rang_from: "",
        numero_rang_to: "",
        vente_acheteur_from: "",
        vente_acheteur_to: "",
      });
      setFormLocked(false);
      setTypes([]);
      setAvailableRanksFrom([]);
      setAvailableRanksTo([]);
    } catch (error) {
      console.error("Failed to add mouvement:", error);
      toast.error("Échec de la création du déplacement !");
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">Ajouter un déplacement d'huîtres</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Sélection du lot */}
        <div>
          <label className="block text-gray-700">Lot</label>
          <select
            name="lot_id"
            value={formData.lot_id}
            onChange={handleLotChange}
            className="w-full px-3 py-2 border rounded"
            disabled={formLocked}
          >
            <option value="">Sélectionner un lot</option>
            {lots.map((lot) => (
              <option key={lot.id} value={lot.id}>
                {lot.nom}
              </option>
            ))}
          </select>
        </div>
        {/* Sélection du type d'huîtres */}
        <div>
          <label className="block text-gray-700">Type d'huîtres</label>
          <select
            name="type_lot_id"
            value={formData.type_lot_id}
            onChange={handleTypeChange}
            className="w-full px-3 py-2 border rounded"
            disabled={!formData.lot_id || formLocked}
          >
            <option value="">Sélectionner un type</option>
            {types.map((type) => (
              <option key={type.id} value={type.id}>
                {type.type_huitre}
              </option>
            ))}
          </select>
        </div>
        {/* Sélection du lieu de départ */}
        <div>
          <label className="block text-gray-700">Lieu de départ</label>
          <select
            name="lieu_from"
            value={formData.lieu_from}
            onChange={handleLieuFromChange}
            className="w-full px-3 py-2 border rounded"
            disabled={formLocked || !formData.type_lot_id}
          >
            <option value="">Sélectionner un lieu de départ</option>
            {locations.map((loc) => (
              <option key={loc.id} value={loc.nom}>
                {loc.nom}
              </option>
            ))}
          </select>
        </div>
        {formData.lieu_from === "Parc" && (
          <div>
            <label className="block text-gray-700">
              Numéro de Rang (Départ)
            </label>
            <select
              name="numero_rang_from"
              value={formData.numero_rang_from}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              disabled={formLocked}
            >
              <option value="">Sélectionner un numéro de rang</option>
              {availableRanksFrom.map((rang) => (
                <option key={rang.numero_rang} value={rang.numero_rang}>
                  {rang.numero_rang} (dispo: {rang.quantite} kg)
                </option>
              ))}
            </select>
          </div>
        )}
        {/* Sélection du lieu d'arrivée */}
        <div>
          <label className="block text-gray-700">Lieu d'arrivée</label>
          <select
            name="lieu_to"
            value={formData.lieu_to}
            onChange={handleLieuToChange}
            className="w-full px-3 py-2 border rounded"
            disabled={formLocked}
          >
            <option value="">Sélectionner un lieu d'arrivée</option>
            {locations.map((loc) => (
              <option key={loc.id} value={loc.nom}>
                {loc.nom}
              </option>
            ))}
          </select>
        </div>
        {formData.lieu_to === "Parc" && (
          <div>
            <label className="block text-gray-700">
              Numéro de Rang (Arrivée)
            </label>
            <select
              name="numero_rang_to"
              value={formData.numero_rang_to}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              disabled={formLocked}
            >
              <option value="">Sélectionner un numéro de rang</option>
              {availableRanksTo.map((rang) => (
                <option key={rang.numero_rang} value={rang.numero_rang}>
                  {rang.numero_rang} (dispo: {rang.quantite} kg)
                </option>
              ))}
            </select>
          </div>
        )}
        {formData.lieu_from === "Vente" && (
          <div>
            <label className="block text-gray-700">Acheteur (Départ)</label>
            <select
              name="vente_acheteur_from"
              value={formData.vente_acheteur_from}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              disabled={formLocked}
            >
              <option value="">Sélectionner un acheteur</option>
              <option value="Restaurant">Restaurant</option>
              <option value="Vente Internet">Vente Internet</option>
              <option value="Dégustation">Dégustation</option>
              <option value="Marché">Marché</option>
              <option value="Distributeur">Distributeur</option>
            </select>
          </div>
        )}
        {formData.lieu_to === "Vente" && (
          <div>
            <label className="block text-gray-700">Acheteur (Arrivée)</label>
            <select
              name="vente_acheteur_to"
              value={formData.vente_acheteur_to}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              disabled={formLocked}
            >
              <option value="">Sélectionner un acheteur</option>
              <option value="Restaurant">Restaurant</option>
              <option value="Vente Internet">Vente Internet</option>
              <option value="Dégustation">Dégustation</option>
              <option value="Marché">Marché</option>
              <option value="Distributeur">Distributeur</option>
            </select>
          </div>
        )}
        <div>
          <label className="block text-gray-700">Date du déplacement</label>
          <input
            type="date"
            name="date_mouvement"
            value={formData.date_mouvement}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border rounded"
            disabled={!formData.lieu_from || !formData.lieu_to || formLocked}
          />
        </div>
        <div>
          <label className="block text-gray-700">Quantité (kg)</label>
          <input
            type="number"
            name="quantite"
            value={formData.quantite}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border rounded"
            disabled={!formData.type_lot_id || formLocked}
            max={getMaxAvailable() || undefined}
          />
          {formData.type_lot_id && formData.lieu_from && (
            <p className="mt-1 text-sm text-gray-600">
              Quantité max disponible : {getMaxAvailable()} kg
            </p>
          )}
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
            disabled={!formData.lieu_from || !formData.lieu_to || formLocked}
          >
            Ajouter
          </button>
        </div>
      </form>
    </div>
  );
};

export default Mouvements;
