import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import {
  fetchVivier,
  addVivierEntry,
  updateVivierEntry,
  deleteVivierEntry,
} from "./api";
import Modal from "react-modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Vivier.css";

Modal.setAppElement("#root");

const Vivier = () => {
  const [vivierEntries, setVivierEntries] = useState([]);
  const [filteredVivierEntries, setFilteredVivierEntries] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [formData, setFormData] = useState({
    date: "",
    description: "",
    comments: "",
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Charge les entrées du vivier depuis l'API
  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await fetchVivier();
        const entries = Array.isArray(response.data) ? response.data : [];
        setVivierEntries(entries);
        setFilteredVivierEntries(entries);
      } catch (error) {
        console.error("Failed to fetch vivier entries:", error);
        toast.error("Échec du chargement des entrées du vivier");
      }
    };

    loadData();
  }, []);

  // Ouvre la modal ; en mode modification, on préremplit le formulaire
  const openModal = (entry = null) => {
    setSelectedEntry(entry);
    if (entry) {
      // Pour l'input, on souhaite un format "yyyy-MM-dd"
      const inputDate = entry.date.split("T")[0];
      setFormData({
        date: inputDate,
        description: entry.description,
        comments: entry.comments,
      });
    } else {
      setFormData({
        date: new Date().toISOString().split("T")[0],
        description: "",
        comments: "",
      });
    }
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedEntry(null);
    setFormData({
      date: "",
      description: "",
      comments: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Ici, on envoie la date en "yyyy-MM-dd" car la colonne est de type DATE
      const updatedFormData = { ...formData, date: formData.date };
      if (selectedEntry) {
        await updateVivierEntry(selectedEntry.id, updatedFormData);
        toast.success(
          `L'entrée du ${format(
            parseISO(formData.date),
            "dd/MM/yyyy"
          )} a été modifiée avec succès`
        );
      } else {
        await addVivierEntry(updatedFormData);
        toast.success(
          `L'entrée du ${format(
            parseISO(formData.date),
            "dd/MM/yyyy"
          )} a été ajoutée avec succès`
        );
      }
      const response = await fetchVivier();
      const entries = Array.isArray(response.data) ? response.data : [];
      setVivierEntries(entries);
      setFilteredVivierEntries(entries);
      closeModal();
    } catch (error) {
      console.error("Failed to save vivier entry:", error);
      toast.error("Échec de la sauvegarde de l'entrée du vivier");
    }
  };

  const handleDelete = async (entryId) => {
    try {
      await deleteVivierEntry(entryId);
      const response = await fetchVivier();
      const entries = Array.isArray(response.data) ? response.data : [];
      setVivierEntries(entries);
      setFilteredVivierEntries(entries);
      toast.success("L'entrée a été supprimée avec succès");
    } catch (error) {
      console.error("Failed to delete vivier entry:", error);
      toast.error("Échec de la suppression de l'entrée");
    }
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);

    if (value) {
      const filteredEntries = vivierEntries.filter((entry) => {
        const entryDate = format(parseISO(entry.date), "dd/MM/yyyy");
        return (
          (entry.description &&
            entry.description.toLowerCase().includes(value.toLowerCase())) ||
          (entry.comments &&
            entry.comments.toLowerCase().includes(value.toLowerCase())) ||
          entryDate.includes(value)
        );
      });
      setFilteredVivierEntries(filteredEntries);
    } else {
      setFilteredVivierEntries(vivierEntries);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl">Vivier</h2>
        <button
          className="bg-primary-green text-white py-2 px-4 rounded hover:bg-green-600"
          onClick={() => openModal()}
        >
          Ajouter
        </button>
      </div>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Rechercher..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full px-3 py-2 border rounded"
        />
      </div>
      <div className="grid grid-cols-1 gap-4">
        {filteredVivierEntries.map((entry) => (
          <div key={entry.id} className="p-4 border rounded space-y-2">
            <div className="flex justify-between items-center">
              <div>
                {/* Formate la date pour l'affichage en dd/MM/yyyy */}
                <h3 className="text-lg font-bold">
                  {format(parseISO(entry.date), "dd/MM/yyyy")}
                </h3>
                <p>{entry.description}</p>
                <p className="text-gray-500">{entry.comments}</p>
              </div>
              <div className="space-x-2">
                <button
                  className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600"
                  onClick={() => openModal(entry)}
                >
                  Modifier
                </button>
                <button
                  className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                  onClick={() => handleDelete(entry.id)}
                >
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Modifier l'entrée du vivier"
        className={`modal-content ${modalIsOpen ? "fade-in" : "fade-out"}`}
        overlayClassName={`modal-overlay ${
          modalIsOpen ? "fade-in" : "fade-out"
        }`}
      >
        <div className="p-6 bg-white rounded-lg shadow-lg max-w-md mx-auto">
          <h2 className="text-2xl mb-4">
            {selectedEntry
              ? `Modifier l'entrée du ${format(
                  parseISO(formData.date),
                  "dd/MM/yyyy"
                )}`
              : "Ajouter une nouvelle entrée"}
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-gray-700">Date</label>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700">Description</label>
              <input
                type="text"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700">Commentaires</label>
              <input
                type="text"
                name="comments"
                value={formData.comments}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
              >
                {selectedEntry ? "Enregistrer" : "Ajouter"}
              </button>
              <button
                type="button"
                onClick={closeModal}
                className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
              >
                Annuler
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default Vivier;
